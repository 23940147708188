import { createApp } from 'vue'
import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/css/main.css'
import './assets/css/codeeditor.css'
import './assets/css/rubberduck.css'
import 'highlight.js/styles/atom-one-dark.css'

// 添加 Vue 特性标志
window.__VUE_OPTIONS_API__ = true
window.__VUE_PROD_DEVTOOLS__ = false
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

createApp(App).mount('#app')
