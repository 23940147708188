export const getApiKey = () => {
  try {
    // 这里可以添加更多的混淆和检查
    const key = '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';
    return key.split('').reverse().join('');
  } catch (e) {
    console.error('Failed to get API key');
    return null;
  }
};

// 添加一些检查函数
export const isValidEnvironment = () => {
  try {
    // 添加一些基本的环境检查
    return window.location.hostname === 'localhost' || 
           window.location.hostname.includes('github.io') ||
           window.location.protocol === 'https:';
  } catch {
    return false;
  }
}; 