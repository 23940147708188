<template>
  <div class="rubber-duck-container">
    <div class="layout-container">
      <!-- 左侧代码编辑器 -->
      <div class="code-editor-section glass-inset">
        <CodeEditor 
          @codeChanged="handleCodeChange"
          @quote="handleQuote" 
        />
      </div>

      <!-- 右侧聊天和语音交互区域 -->
      <div class="chat-section">
        <!-- 顶部工具栏 -->
        <div class="top-toolbar glass-inset">
          <div class="toolbar-content">
            <!-- 左侧小黄鸭 -->
            <div class="duck-icon" :class="{ 'animate-wiggle': isAnimating }" @click="playDuckAnimation">
              <img :src="duckImage" alt="Rubber Duck" class="duck-image">
              <audio ref="quackAudio" preload="auto">
                <source :src="quackSound" type="audio/mpeg">
              </audio>
            </div>

            <!-- 中间模式选择器 -->
            <select 
              v-model="selectedMode" 
              class="mode-select"
              :disabled="isProcessing || isContinuousMode"
              :title="isContinuousMode ? 'Please stop continuous chat first' : (isProcessing ? 'Please wait for current operation to complete' : 'Select mode')"
            >
              <option v-for="mode in modes" :key="mode" :value="mode">
                {{ mode }}
              </option>
            </select>

            <!-- 右侧清除按钮 -->
            <button @click="clearChat" class="clear-button" title="Clear chat history">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>

        <!-- 聊天容器 -->
        <div class="chat-container glass-inset">
          <div class="messages-wrapper" ref="messagesWrapper">
            <div v-for="message in chatHistory" :key="message.id" 
                 :class="[
                   message.sender, 
                   'message',
                   {
                     'generating-audio': isMessageGeneratingAudio(message.id),
                     'playing-audio': isMessagePlaying(message.id)
                   }
                 ]">
              <!-- 仅为AI消息添加播放按钮 -->
              <button v-if="message.sender === 'assistant' && selectedMode !== 'Silent Duck'"
                      @click="playMessage(message)"
                      class="message-play-button"
                      :class="{ 
                        'playing': isMessagePlaying(message.id),
                        'loading': isMessageGeneratingAudio(message.id)
                      }"
                      :disabled="isMessageGeneratingAudio(message.id)"
                      :title="getPlayButtonTitle(message.id)">
                <i class="fas" :class="getPlayButtonIcon(message.id)"></i>
              </button>
              
              <!-- 原有的消息内容渲染 -->
              <template v-for="(block, index) in parseMessage(message.text)" :key="index">
                <!-- 代码块 -->
                <div v-if="block.type === 'code'" class="code-block glass-inset">
                  <div class="code-header">
                    <span class="language-label">{{ block.language }}</span>
                    <button 
                      @click="copyCode(block.content)" 
                      class="copy-button" 
                      :class="{ 'copied': copiedStates[block.content] }"
                      title="Copy code"
                    >
                      <i class="fas" :class="copiedStates[block.content] ? 'fa-check' : 'fa-copy'"></i>
                      <span class="copy-text">{{ copiedStates[block.content] ? 'Copied!' : 'Copy' }}</span>
                    </button>
                  </div>
                  <pre><code :class="block.language" v-html="highlightCode(block.content, block.language)"></code></pre>
                </div>
                <!-- 普通文本 -->
                <div v-else class="text-content">{{ block.content }}</div>
              </template>
            </div>
            <div v-if="selectedMode === 'Silent' && silentResponse" 
                 class="assistant message italic">
              {{ silentResponse }}
            </div>
            
            <!-- 加载指示器 -->
            <div v-if="isLoading || isGeneratingAudio" class="loading-indicator">
              <div class="loading-spinner"></div>
              <span>{{ loadingMessage }}</span>
            </div>
          </div>
        </div>

        <!-- 语音交互快捷控制 -->
        <div class="voice-interaction-bar glass-inset">
          <div class="voice-controls">
            <div class="voice-record-group">
              <!-- 录音按钮 - 仅在非连续模式时显示 -->
              <button 
                v-if="!isContinuousMode"
                @click="toggleRecording"
                class="voice-record-button"
                :class="{ 
                  'recording': isRecording,
                  'disabled': !isMicrophoneEnabled 
                }"
                :disabled="isContinuousMode || !isMicrophoneEnabled"
                :title="!isMicrophoneEnabled ? 'Please enable microphone first' : (isRecording ? 'Finish recording' : 'Start voice input')"
              >
                <div class="button-content">
                  <i class="fas" :class="isRecording ? 'fa-check' : 'fa-microphone'"></i>
                  <span class="button-text">{{ isRecording ? 'Finish Recording' : 'Voice Input' }}</span>
                </div>
                <div v-if="isRecording" class="recording-status">
                  <div class="pulse-dot"></div>
                  <span>{{ recordingDuration }}s</span>
                </div>
              </button>

              <!-- 连续对话按钮 - 仅在非录音状态时显示 -->
              <button 
                v-if="!isRecording"
                @click="toggleContinuousMode"
                class="continuous-mode-button"
                :class="{ 
                  'active': isContinuousMode,
                  'expanded': isContinuousMode || !isRecording,
                  'disabled': !isMicrophoneEnabled 
                }"
                :disabled="!isMicrophoneEnabled"
                :title="!isMicrophoneEnabled ? 'Please enable microphone first' : (isContinuousMode ? 'Stop continuous chat' : 'Start continuous chat')"
              >
                <div class="button-content">
                  <i class="fas" :class="isContinuousMode ? 'fa-comment-slash' : 'fa-comments'"></i>
                  <span class="button-text">{{ isContinuousMode ? 'Stop Chat' : 'Continuous Chat' }}</span>
                </div>
              </button>

              <!-- 修改状态提示为按钮式设计 -->
              <div v-if="isContinuousMode" 
                   class="status-button"
                   :class="[
                     continuousModeState,
                     { 'disabled': isPlaying || isProcessing || isGeneratingAudio }
                   ]">
                <div class="status-content">
                  <i class="fas" :class="getStatusIcon"></i>
                  <span class="status-text">{{ getStatusMessage }}</span>
                  <div class="status-indicator"></div>
                </div>
              </div>
            </div>

            <!-- 波形显示 -->
            <div v-if="isRecording" class="waveform-display">
              <div class="audio-visualizer">
                <div v-for="n in 32" :key="n" class="wave-bar"></div>
              </div>
            </div>

            <!-- 音频播放控制按钮 -->
            <button 
              v-if="isPlaying"
              @click="stopCurrentAudio"
              class="audio-control-button"
              title="Stop playback"
            >
              <i class="fas fa-stop"></i>
            </button>

            <!-- 麦克风控制按钮 -->
            <button 
              @click="toggleMicrophoneAccess"
              class="microphone-control-button"
              :class="{ 'disabled': !isMicrophoneEnabled }"
              :title="isMicrophoneEnabled ? 'Disable microphone' : 'Enable microphone'"
            >
              <i class="fas" :class="isMicrophoneEnabled ? 'fa-microphone' : 'fa-microphone-slash'"></i>
            </button>

            <!-- 设置按钮 -->
            <button 
              @click="openVoiceSettings"
              class="voice-settings-button"
              title="Voice Settings"
            >
              <i class="fas fa-sliders-h"></i>
            </button>
          </div>
        </div>

        <!-- 语音设置对话框 -->
        <dialog ref="voiceSettingsDialog" class="settings-dialog glass-morphism" @click="handleDialogClick">
          <div class="dialog-content" @click.stop>
            <div class="dialog-header">
              <div class="header-title">
                <i class="fas fa-sliders-h"></i>
                <h3>Voice Settings</h3>
              </div>
              <button @click="closeVoiceSettings" class="close-button" title="Close settings">
                <i class="fas fa-times"></i>
              </button>
            </div>

            <div class="dialog-body">
              <!-- TTS 开关 -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-volume-up"></i>
                    <span>Text-to-Speech</span>
                  </div>
                  <label class="switch">
                    <input type="checkbox" v-model="ttsEnabled">
                    <span class="slider"></span>
                  </label>
                </div>
                <div class="setting-description">
                  Automatically read AI responses aloud
                </div>
              </div>

              <!-- 声音选择 -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-microphone-alt"></i>
                    <span>Voice Selection</span>
                  </div>
                </div>
                <div class="voice-options">
                  <button 
                    v-for="voice in voices" 
                    :key="voice.id"
                    @click="selectedVoice = voice.id"
                    :class="['voice-option', { active: selectedVoice === voice.id }]"
                  >
                    <div class="voice-icon">
                      <i :class="voice.icon"></i>
                    </div>
                    <div class="voice-info">
                      <div class="voice-name">{{ voice.name }}</div>
                      <div class="voice-description">{{ voice.description }}</div>
                    </div>
                    <div class="voice-check">
                      <i class="fas fa-check"></i>
                    </div>
                  </button>
                </div>
              </div>

              <!-- 音量控制 -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas" :class="volumeIcon"></i>
                    <span>Volume</span>
                  </div>
                  <span class="volume-value">{{ volume }}%</span>
                </div>
                <div class="volume-slider-container">
                  <input 
                    type="range" 
                    v-model="volume" 
                    min="0" 
                    max="100" 
                    class="volume-slider"
                    :style="{ '--volume-percentage': volume + '%' }"
                  >
                </div>
              </div>

              <!-- 添加超时设置组 -->
              <div class="setting-group">
                <div class="setting-header">
                  <div class="setting-title">
                    <i class="fas fa-clock"></i>
                    <span>Inactivity Timeout</span>
                  </div>
                </div>
                <div class="setting-description">
                  Automatically stop continuous chat mode after period of inactivity
                </div>
                <div class="timeout-slider-container">
                  <div class="timeout-value">
                    <span>{{ formatTimeout(inactivityTimeout) }}</span>
                  </div>
                  <input 
                    type="range" 
                    v-model="inactivityTimeout" 
                    min="10" 
                    max="300" 
                    step="10"
                    class="timeout-slider"
                    :style="{ '--timeout-percentage': (inactivityTimeout - 10) / (300 - 10) * 100 + '%' }"
                  >
                  <div class="timeout-labels">
                    <span>10s</span>
                    <span>5m</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-footer">
              <button @click="closeVoiceSettings" class="confirm-button">
                <div class="button-content">
                  <i class="fas fa-check"></i>
                  <span>Apply Settings</span>
                </div>
                <div class="button-background"></div>
              </button>
            </div>
          </div>
        </dialog>

        <!-- 输入区域 -->
        <div class="input-container glass-inset">
          <input 
            v-model="userInput" 
            @keydown.enter.prevent="handleEnterPress"
            @compositionstart="isComposing = true"
            @compositionend="isComposing = false"
            placeholder="Explain your code..." 
            class="chat-input"
            :disabled="isSending || isProcessing"
          >
          <button 
            @click="sendMessage" 
            class="send-button"
            :disabled="isSending || isProcessing || !userInput.trim()"
          >
            <i class="fas" :class="isSending ? 'fa-spinner fa-spin' : 'fa-paper-plane'"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- 添加清理确认对话框 -->
    <dialog ref="clearConfirmDialog" class="settings-dialog glass-morphism" @click="handleClearDialogClick">
      <div class="dialog-content" @click.stop>
        <div class="dialog-header">
          <div class="header-title">
            <i class="fas fa-trash-alt"></i>
            <h3>Clear Chat History</h3>
          </div>
          <button @click="closeClearConfirm" class="close-button">
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="dialog-body">
          <p class="confirm-message">
            Are you sure you want to clear the current chat history? 
            <span class="confirm-detail">This will only clear the chat history for the current mode ({{ selectedMode }}).</span>
          </p>
        </div>

        <div class="dialog-footer">
          <div class="button-group">
            <button @click="closeClearConfirm" class="cancel-button">
              Cancel
            </button>
            <button @click="confirmClear" class="danger-button">
              <div class="button-content">
                <i class="fas fa-trash-alt"></i>
                <span>Clear History</span>
              </div>
              <div class="button-background"></div>
            </button>
          </div>
        </div>
      </div>
    </dialog>

    <!-- 添加状态提示组件 -->
    <Transition name="fade">
      <div v-if="statusMessage" class="status-toast" :class="statusType">
        <div class="status-content">
          <div class="status-icon">
            <i class="fas" :class="statusIcon"></i>
          </div>
          <span class="status-text">{{ statusMessage }}</span>
        </div>
        <div class="progress-bar"></div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { ref, watch, nextTick, onUnmounted, computed, onMounted } from 'vue'
import duckImage from '@/assets/rubber-duck.png'
import CodeEditor from './CodeEditor.vue'
import hljs from 'highlight.js'
import { getApiKey, isValidEnvironment } from '../config/api'
// 导音文件
import quackSound from '@/assets/sounds/quack.mp3'
import { silentResponses } from '../config/silentresponse'
import { modePrompts } from '../config/modeprompts'


export default {
  name: 'RubberDuckAssistant',
  components: {
    CodeEditor
  },
  props: {
    currentCode: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // 首先定义 localCode
    const localCode = ref({
      code: '',
      language: 'javascript'
    });

    // 然后是其他状态定义
    const modes = ['Silent Duck', 'Beginner Friend', 'Expert'];
    const selectedMode = ref('Silent Duck');
    const isAnimating = ref(false);
    const userInput = ref('');
    const chatHistories = ref({
      'Silent Duck': [],
      'Beginner Friend': [],
      'Expert': []
    });

    // 现在可以安全使用 watch
    watch(() => props.currentCode, (newCode) => {
      if (newCode) {
        localCode.value = {
          code: newCode.code || '',
          language: newCode.language || 'javascript'
        };
      }
    }, { deep: true, immediate: true });

    // 更新代码的方法
    const updateCode = (newCode) => {
      const updatedCode = {
        code: newCode,
        language: localCode.value.language
      };
      emit('update:currentCode', updatedCode);
      localCode.value = updatedCode;
    };

    const handleTranscription = (text) => {
      if (text && text.trim()) {
        // 如果输入框已有内容，则在后面添加新的文本
        userInput.value = userInput.value
          ? `${userInput.value}\n\n${text.trim()}`  // 用两个换行符分隔，使文本更清晰
          : text.trim();
        
        // 如果不是连续对话模式，则立即发送
        if (!isContinuousMode.value) {
          sendMessage();
        }
      }
    };

    const isComposing = ref(false);
    const isSending = ref(false);

    const handleEnterPress = () => {
      if (isComposing.value || isSending.value || !userInput.value.trim()) {
        return;
      }
      sendMessage();
    };

    const isPlaying = ref(false);
    let currentAudio = null;

    // 停止当前音频播放
    const stopCurrentAudio = async () => {
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.currentTime = 0;
        URL.revokeObjectURL(currentAudio.src);
        currentAudio = null;
        isPlaying.value = false;
        playingMessageId.value = null;
        
        // 如果仍处于连续对话模式，恢复录音
        if (isContinuousMode.value) {
          await resumeRecording();
        }
      }
    };

    // 加动底部的方法
    const scrollToBottom = () => {
      if (messagesWrapper.value) {
        const scrollOptions = {
          top: messagesWrapper.value.scrollHeight,
          behavior: 'smooth'
        };
        messagesWrapper.value.scrollTo(scrollOptions);
      }
    };

    // 修改发送消息方法
    const sendMessage = async () => {
      if (isSending.value || !userInput.value?.trim()) return;

      stopCurrentAudio();
      isSending.value = true;
      isLoading.value = true;

      try {
        const messageText = userInput.value;
        userInput.value = '';

        const currentCodeState = {
          code: localCode.value.code || '',
          language: localCode.value.language || 'javascript'
        };

        // 更新当前模式的天历史
        const currentHistory = [...chatHistories.value[selectedMode.value]];
        currentHistory.push({ 
          id: Date.now(), 
          sender: 'user', 
          text: messageText,
          code: currentCodeState
        });
        chatHistories.value[selectedMode.value] = currentHistory;

        // 滚动到底部显示用户消息
        nextTick(scrollToBottom);

        animateDuck();
        
        if (selectedMode.value === 'Silent Duck') {
          // Silent Duck 模式的处理
          const responseCount = Math.floor(Math.random() * 2) + 2;
          const responses = [];
          const usedIndices = new Set();
          
          while (responses.length < responseCount) {
            const index = Math.floor(Math.random() * silentResponses.length);
            if (!usedIndices.has(index)) {
              usedIndices.add(index);
              responses.push(silentResponses[index]);
            }
          }
          
          const response = responses.join('\n');
          
          currentHistory.push({ 
            id: Date.now(), 
            sender: 'assistant', 
            text: response,
            code: currentCodeState
          });
          chatHistories.value[selectedMode.value] = currentHistory;
        } else {
          const response = await callOpenAI(
            selectedMode.value, 
            messageText, 
            currentCodeState,
            currentHistory
          );

          currentHistory.push({ 
            id: Date.now(), 
            sender: 'assistant', 
            text: response,
            code: currentCodeState
          });
          chatHistories.value[selectedMode.value] = currentHistory;

          // 滚动到底部显示手回复
          nextTick(scrollToBottom);

          if (ttsEnabled.value) {
            isGeneratingAudio.value = true;
            // 滚动到底部显示音频生成提示
            nextTick(scrollToBottom);

            try {
              const audioResponse = await fetch('https://api.openai.com/v1/audio/speech', {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  model: 'tts-1',
                  voice: selectedVoice.value,
                  input: response
                })
              });

              const audioBlob = await audioResponse.blob();
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              
              // 设置音频事件
              audio.volume = volume.value / 100;
              currentAudio = audio;
              // 设置当前播放的消息ID
              playingMessageId.value = currentHistory[currentHistory.length - 1].id;
              
              audio.onplay = () => {
                isPlaying.value = true;
              };
              
              audio.onended = () => {
                isPlaying.value = false;
                playingMessageId.value = null;
                URL.revokeObjectURL(audioUrl);
                currentAudio = null;
              };
              
              audio.onpause = () => {
                isPlaying.value = false;
                playingMessageId.value = null;
              };
              
              await audio.play();
            } catch (err) {
              console.error('Error playing TTS:', err);
            } finally {
              isGeneratingAudio.value = false;
              // 最后一滚动确保所有内容可见
              nextTick(scrollToBottom);
            }
          }
        }

        // 保存更新后的历史记录
        saveChatsToLocalStorage();

        // 在消息发送完成后，如果是连续对话模式，重新初始化音频监听
        if (isContinuousMode.value) {
          await reinitializeAudioContext();
        }
      } catch (error) {
        console.error('Error sending message:', error);
        showStatus('Failed to send message', 'error');
      } finally {
        isSending.value = false;
        isLoading.value = false;
        nextTick(scrollToBottom);
      }
    };

    const callOpenAI = async (mode, message, codeState, currentHistory) => {
      if (!isValidEnvironment()) {
        throw new Error('Invalid environment for API calls');
      }

      const apiKey = getApiKey();
      if (!apiKey) {
        throw new Error('Failed to get API key');
      }
      
      // 添加带行号的代码格式化函数
      const formatCodeWithLineNumbers = (code, language) => {
        if (!code?.trim()) return '';
        const lines = code.split('\n');
        const numberedLines = lines.map((line, index) => `${index + 1}| ${line}`).join('\n');
        return `\n\nCurrent code (${language}):\n\`\`\`${language}\n${numberedLines}\n\`\`\``;
      };

      // 检查并格式化代码上下文
      const codeContext = codeState.code?.trim() 
        ? formatCodeWithLineNumbers(codeState.code, codeState.language)
        : '';

      // 修改消息历史的格式化方式
      const messageHistory = currentHistory
        .filter(msg => msg.code?.code?.trim() || !msg.code)
        .map(msg => {
          const msgCodeContext = msg.code?.code?.trim()
            ? formatCodeWithLineNumbers(msg.code.code, msg.code.language)
            : '';
          return {
            role: msg.sender === 'user' ? 'user' : 'assistant',
            content: msg.text + msgCodeContext
          };
        });

      // 添加系统消息中的行号说明
      const systemMessage = `${modePrompts[mode]}\n\nWhen users refer to specific line numbers in their messages, you can find the line content in the numbered code format (e.g., "1| console.log('Hello')"). Please reference these line numbers in your responses when discussing specific code lines.`;

      console.log('Sending request with messages:', {
        mode,
        message,
        codeContext,
        messageHistory
      });

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey.split('').reverse().join('')}`
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          messages: [
            { 
              role: 'system', 
              content: systemMessage
            },
            ...messageHistory,
            { 
              role: 'user', 
              content: message + codeContext
            }
          ]
        })
      });

      if (!response.ok) {
        console.error('OpenAI API error:', await response.json());
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.choices[0].message.content;
    };

    const clearAllChats = () => {
      Object.keys(chatHistories.value).forEach(mode => {
        chatHistories.value[mode] = [];
      });
      saveChatsToLocalStorage();
    };

    watch(selectedMode, (newMode, oldMode) => {
      if (newMode !== oldMode) {
        // 停止当前音频播放
        stopCurrentAudio();
        
        // 如果正在处理中，回退到原来的模式
        if (isProcessing.value) {
          selectedMode.value = oldMode;
          showStatus('Please wait for current operation to complete', 'warning');
          return;
        }
      }
    });

    const handleProcessingStart = () => {
      // 处语音转文字的开始
    };

    const handleProcessingError = () => {
      console.error('语音处理出错');
    };

    const animateDuck = () => {
      isAnimating.value = true
      setTimeout(() => { isAnimating.value = false }, 2000)
    }

    const handleCodeChange = (codeData) => {
      console.log('Code changed:', codeData);
      if (codeData && (codeData.code !== localCode.value.code || codeData.language !== localCode.value.language)) {
        localCode.value = {
          code: codeData.code || '',
          language: codeData.language || 'javascript'
        };
        emit('update:currentCode', localCode.value);
      }
    };

    // 添加新状态
    const showVoiceSettings = ref(false);
    const chatContainer = ref(null);
    const messagesWrapper = ref(null);

    // 切换语音设置面板
    const toggleVoiceSettings = () => {
      showVoiceSettings.value = !showVoiceSettings.value;
    };

    // 加 chatHistory 计算属性
    const chatHistory = computed(() => chatHistories.value[selectedMode.value] || []);

    // 添加新的状态变量
    const isRecording = ref(false);
    const recordingDuration = ref(0);
    let recordingTimer = null; // 不需要 ref 因为不需要响应式

    // 添加 voiceInteraction ref
    const voiceInteraction = ref(null);

    // 修改 toggleRecording 方法
    const toggleRecording = async () => {
      if (!isRecording.value) {
        try {
          // 开始新录音前清空之前的音频数据
          audioChunks.value = [];
          
          await initAudioContext();
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          
          // 创建音频源和分析器连接
          if (audioContext.value && analyser.value) {
            const source = audioContext.value.createMediaStreamSource(stream);
            source.connect(analyser.value);
          }
          
          mediaRecorder.value = new MediaRecorder(stream);
          
          mediaRecorder.value.ondataavailable = (event) => {
            audioChunks.value.push(event.data);
          };

          mediaRecorder.value.onstop = async () => {
            // 只有在不是取消状态下才处理音频数据
            if (audioChunks.value.length > 0 && mediaRecorder.value && !mediaRecorder.value.cancelled) {
              isProcessing.value = true;
              const audioBlob = new Blob(audioChunks.value, { type: 'audio/wav' });
              await sendAudioToOpenAI(audioBlob);
              audioChunks.value = [];
              isProcessing.value = false;
            }
            // 确保在停止后清理音频流
            if (mediaRecorder.value?.stream) {
              mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
            }
          };

          mediaRecorder.value.start();
          isRecording.value = true;
          startRecordingTimer();
        } catch (err) {
          console.error('Error starting recording:', err);
          showStatus('Failed to start recording', 'error');
          isRecording.value = false;
          stopRecordingTimer();
        }
      } else {
        if (mediaRecorder.value) {
          // 标记这是正常停止，不是取消
          mediaRecorder.value.cancelled = false;
          mediaRecorder.value.stop();
          // 确保立即清理音频流
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        isRecording.value = false;
        stopRecordingTimer();
      }
    };

    // 添加录音时器相关方法
    const startRecordingTimer = () => {
      recordingDuration.value = 0;
      recordingTimer = setInterval(() => {
        recordingDuration.value += 1;
      }, 1000);
    };

    const stopRecordingTimer = () => {
      if (recordingTimer) {
        clearInterval(recordingTimer);
        recordingTimer = null;
      }
      // 确保清理音频流
      if (mediaRecorder.value?.stream) {
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
    };

    // 组件卸载时清理计时器
    onUnmounted(() => {
      stopRecordingTimer();
      stopCurrentAudio();
      // 确保清理音频上下文和流
      if (audioContext.value) {
        audioContext.value.close();
      }
      if (mediaRecorder.value?.stream) {
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
    });

    // 添加消息解析方法
    const parseMessage = (text) => {
      const blocks = [];
      let currentIndex = 0;

      // 匹配代码块的正则表达式
      const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
      let match;

      while ((match = codeBlockRegex.exec(text)) !== null) {
        // 添加代码块之前的文
        if (match.index > currentIndex) {
          blocks.push({
            type: 'text',
            content: text.slice(currentIndex, match.index)
          });
        }

        // 添加代码块
        blocks.push({
          type: 'code',
          language: match[1] || 'text',
          content: match[2].trim()
        });

        currentIndex = match.index + match[0].length;
      }

      // 添加剩余的文本
      if (currentIndex < text.length) {
        blocks.push({
          type: 'text',
          content: text.slice(currentIndex)
        });
      }

      return blocks;
    };

    // 添加复制代码方法
    const copiedStates = ref({});

    // 修改复制代码方法
    const copyCode = async (code) => {
      try {
        await navigator.clipboard.writeText(code);
        copiedStates.value[code] = true;
        setTimeout(() => {
          copiedStates.value[code] = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy code:', err);
      }
    };

    // 添加代码高亮方法
    const highlightCode = (code, language) => {
      try {
        if (language) {
          return hljs.highlight(code, { language }).value;
        }
        return hljs.highlightAuto(code).value;
      } catch (e) {
        console.warn('Failed to highlight code:', e);
        return code;
      }
    };

    // 添加状态提示方法
    const showStatus = (message, type = 'info', duration = 3000) => {
      statusMessage.value = message;
      statusType.value = type;
      
      // 自动隐藏
      setTimeout(() => {
        statusMessage.value = '';
        statusType.value = '';
      }, duration);
    };

    // 添加音频上下文和分析器
    const audioContext = ref(null);
    const analyser = ref(null);
    const mediaRecorder = ref(null);
    const audioChunks = ref([]);
    const dataArray = ref(null);

    // 修改 initAudioContext 方法
    const initAudioContext = async () => {
      if (!audioContext.value) {
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;
        dataArray.value = new Uint8Array(analyser.value.frequencyBinCount);

        // 使用 dataArray.value 来更新波形示
        const updateWaveform = () => {
          if (!isRecording.value) return;
          
          analyser.value.getByteTimeDomainData(dataArray.value);
          
          // 使用数据更新波形条的高度
          const bars = document.querySelectorAll('.wave-bar');
          const step = Math.floor(dataArray.value.length / bars.length);
          
          bars.forEach((bar, index) => {
            const dataIndex = index * step;
            const value = dataArray.value[dataIndex] / 128.0; // 将值归一化到 0-2 范围
            const height = Math.max(0.3, Math.min(1, value)) * 100; // 限制高度在 30%-100% 之间
            bar.style.height = `${height}%`;
          });

          requestAnimationFrame(updateWaveform);
        };

        // 开始波形动画
        updateWaveform();
      }
    };

    // 添加音频相关方法
    const sendAudioToOpenAI = async (audioBlob) => {
      try {
        const formData = new FormData();
        formData.append('file', audioBlob);
        formData.append('model', 'whisper-1');
        
        const apiKey = getApiKey();
        if (!apiKey) {
          throw new Error('Failed to get API key');
        }

        const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${apiKey.split('').reverse().join('')}`
          },
          body: formData
        });
        
        const data = await response.json();
        handleTranscription(data.text);
      } catch (err) {
        console.error('Error in speech to text:', err);
        handleProcessingError(err);
      }
    };

    // 添加语音相关状态
    const ttsEnabled = ref(true); // 默认开启动播放
    const selectedVoice = ref('nova');
    const volume = ref(80);
    const voiceSettingsDialog = ref(null);

    // 语音设置相关方法
    const openVoiceSettings = () => {
      voiceSettingsDialog.value?.showModal();
    };

    const closeVoiceSettings = () => {
      voiceSettingsDialog.value?.close();
    };

    const voices = [
      {
        id: 'alloy',
        name: 'Alloy',
        description: 'Balanced and professional',
        icon: 'fas fa-user-tie'
      },
      {
        id: 'echo',
        name: 'Echo',
        description: 'Warm and engaging',
        icon: 'fas fa-smile'
      },
      {
        id: 'fable',
        name: 'Fable',
        description: 'Expressive and dynamic',
        icon: 'fas fa-theater-masks'
      },
      {
        id: 'onyx',
        name: 'Onyx',
        description: 'Deep and authoritative',
        icon: 'fas fa-user-shield'
      },
      {
        id: 'nova',
        name: 'Nova',
        description: 'Friendly and approachable',
        icon: 'fas fa-user-friends'
      },
      {
        id: 'shimmer',
        name: 'Shimmer',
        description: 'Clear and articulate',
        icon: 'fas fa-comment-dots'
      }
    ];

    const quackAudio = ref(null);

    const playDuckAnimation = () => {
      if (isAnimating.value) return;

      isAnimating.value = true;
      
      // 播放音效，调整音量
      if (quackAudio.value) {
        quackAudio.value.currentTime = 0;
        quackAudio.value.volume = 0.3; // 将音量调整为 30%  
        quackAudio.value.play().catch(err => {
          console.error('Error playing quack sound:', err);
        });
      }

      // 动画结束重置状态
      setTimeout(() => {
        isAnimating.value = false;
      }, 1000);
    };

    // 在组件挂载时加载历史记录
    onMounted(() => {
      loadChatsFromLocalStorage();
    });

    // 添加本代码状态
    const silentResponse = ref('');

    // 添加本地存储相关方法
    const STORAGE_KEY = 'rubber-duck-chat-histories';

    const saveChatsToLocalStorage = () => {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(chatHistories.value));
      } catch (e) {
        console.error('Failed to save chat histories:', e);
      }
    };

    const loadChatsFromLocalStorage = () => {
      try {
        const saved = localStorage.getItem(STORAGE_KEY);
        if (saved) {
          chatHistories.value = JSON.parse(saved);
        }
      } catch (e) {
        console.error('Failed to load chat histories:', e);
      }
    };

    const clearConfirmDialog = ref(null);

    // 修改清理相关方法
    const showClearConfirm = () => {
      clearConfirmDialog.value?.showModal();
    };

    const closeClearConfirm = () => {
      clearConfirmDialog.value?.close();
    };

    const handleClearDialogClick = (e) => {
      if (e.target === clearConfirmDialog.value) {
        closeClearConfirm();
      }
    };

    const confirmClear = () => {
      // 停止当前音频播放
      stopCurrentAudio();
      
      // 如果正在处理中，不允许清理
      if (isProcessing.value) {
        showStatus('Please wait for current operation to complete', 'warning');
        return;
      }

      chatHistories.value[selectedMode.value] = [];
      saveChatsToLocalStorage();
      closeClearConfirm();
      
      // 显示成功提示
      showStatus('Chat history cleared successfully', 'success');
    };

    // 修改清理按钮的点击处理
    const clearChat = () => {
      showClearConfirm();
    };

    // 添加新的状态
    const isLoading = ref(false);
    const isGeneratingAudio = ref(false);
    const loadingMessage = computed(() => {
      if (isGeneratingAudio.value) return 'Generating audio response...';
      if (isLoading.value) return 'Processing your message...';
      return '';
    });

    // 添音量图标计算属性
    const volumeIcon = computed(() => {
      if (volume.value === 0) return 'fa-volume-mute';
      if (volume.value < 30) return 'fa-volume-off';
      if (volume.value < 70) return 'fa-volume-down';
      return 'fa-volume-up';
    });

    const statusMessage = ref('');
    const statusType = ref('');
    
    // 状态图标计算属性
    const statusIcon = computed(() => {
      switch (statusType.value) {
        case 'success':
          return 'fa-check-circle';
        case 'error':
          return 'fa-exclamation-circle';
        case 'info':
          return 'fa-info-circle';
        default:
          return 'fa-info-circle';
      }
    });

    // 添加处理中状态
    const isProcessing = computed(() => {
      return isSending.value || isGeneratingAudio.value || isLoading.value;
    });

    const playingMessageId = ref(null);

    const isMessagePlaying = (messageId) => {
      return playingMessageId.value === messageId;
    };

    const playMessage = async (message) => {
      // 如果正在播放这条消息，则停止播放
      if (isMessagePlaying(message.id)) {
        await stopCurrentAudio();
        return;
      }

      try {
        // 停止当前正在播放的任何音频
        await stopCurrentAudio();
        
        // 如果正在录音，暂停录音
        if (mediaRecorder.value?.state === 'recording') {
          pauseRecording();
        }
        
        generatingAudioMessageId.value = message.id;
        
        const audioResponse = await fetch('https://api.openai.com/v1/audio/speech', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'tts-1',
            voice: selectedVoice.value,
            input: message.text
          })
        });

        const audioBlob = await audioResponse.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);
        
        audio.volume = volume.value / 100;
        currentAudio = audio;
        playingMessageId.value = message.id;
        
        audio.onplay = () => {
          isPlaying.value = true;
          // 暂停音量监测
          pauseVolumeMonitoring();
        };
        
        audio.onended = async () => {
          isPlaying.value = false;
          playingMessageId.value = null;
          URL.revokeObjectURL(audioUrl);
          currentAudio = null;
          
          // 如果仍处于连续对话模式，恢复录音
          if (isContinuousMode.value) {
            await resumeRecording();
          }
        };
        
        audio.onpause = () => {
          isPlaying.value = false;
          playingMessageId.value = null;
        };
        
        await audio.play();
      } catch (err) {
        console.error('Error playing message:', err);
        showStatus('Failed to play message', 'error');
      } finally {
        generatingAudioMessageId.value = null;
      }
    };

    const cancelRecording = () => {
      if (mediaRecorder.value) {
        // 标记这是取消操作
        mediaRecorder.value.cancelled = true;
        
        // 确保停止录音
        try {
          mediaRecorder.value.stop();
        } catch (e) {
          console.error('Error stopping recorder:', e);
        }
        // 确保清理音频流
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        mediaRecorder.value = null;
      }
      // 立即清空音频数据
      audioChunks.value = [];
      stopRecordingTimer();
      isRecording.value = false;
      
      showStatus('Recording cancelled', 'info');
    };

    // 在 setup 中添加新的状态方法
    const isContinuousMode = ref(false);
    const continuousModeState = ref('waiting');
    const silenceTimer = ref(null);
    const silenceThreshold = 0.01; // 降低阈值
    const silenceDelay = 1500; // 减少等待时间到1.5秒

    // 连对话模式的状态和消息
    const continuousModeMessage = computed(() => {
      switch (continuousModeState.value) {
        case 'listening':
          return 'Listening... Speak freely';
        case 'processing':
          return 'Processing your message...';
        case 'waiting':
          return 'Waiting for response...';
        default:
          return '';
      }
    });

    const toggleContinuousMode = async () => {
      if (!isContinuousMode.value) {
        try {
          await startContinuousMode();
        } catch (err) {
          console.error('Failed to start continuous mode:', err);
          showStatus('Failed to start continuous chat', 'error');
        }
      } else {
        stopContinuousMode();
      }
    };

    const startContinuousMode = async () => {
      if (!isMicrophoneEnabled.value) {
        showStatus('Please enable microphone first', 'warning');
        return;
      }
      
      try {
        // 先停止任何现有的音频上下文
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }

        // 初始化新的音频上下文和分析器
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;
        
        // 请求麦克风权限并获取音流
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        // 创建音频源并连接分析器
        const source = audioContext.value.createMediaStreamSource(stream);
        source.connect(analyser.value);
        
        // 初始化录音机
        if (mediaRecorder.value) {
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        mediaRecorder.value = new MediaRecorder(stream);
        audioChunks.value = [];
        
        // 设置录音数据处理
        mediaRecorder.value.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.value.push(event.data);
          }
        };
        
        // 重新开始音量监测
        await startVolumeMonitoring();
        
        // 更新状态
        isContinuousMode.value = true;
        continuousModeState.value = 'listening';
        showStatus('Continuous chat mode activated', 'success');
      } catch (err) {
        console.error('Error initializing audio:', err);
        stopContinuousMode();
        throw new Error('Failed to initialize audio system');
      }
    };

    const stopContinuousMode = () => {
      if (mediaRecorder.value) {
        mediaRecorder.value.stop();
        mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
      }
      if (audioContext.value) {
        audioContext.value.close().catch(console.error);
        audioContext.value = null;
      }
      if (analyser.value) {
        analyser.value = null;
      }
      clearTimeout(silenceTimer.value);
      
      isContinuousMode.value = false;
      continuousModeState.value = 'waiting';
      showStatus('Continuous chat mode deactivated', 'info');
    };

    const startVolumeMonitoring = () => {
      if (!analyser.value) return;
      
      const dataArray = new Float32Array(analyser.value.frequencyBinCount);
      let isSpeaking = false;
      let isProcessing = false;
      let volumeHistory = [];
      const historyLength = 10;
      let isMonitoring = true;
      let noSoundTimer = null;
      
      const checkVolume = () => {
        if (!analyser.value || !isMonitoring || isPlaying.value || isProcessing.value || isGeneratingAudio.value) {
          requestAnimationFrame(checkVolume);
          return;
        }
        
        analyser.value.getFloatTimeDomainData(dataArray);
        const volumeLevel = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
        
        volumeHistory.push(volumeLevel);
        if (volumeHistory.length > historyLength) {
          volumeHistory.shift();
        }
        
        const avgVolume = volumeHistory.reduce((a, b) => a + b, 0) / volumeHistory.length;
        const volumeDropped = volumeHistory.length === historyLength && volumeLevel < avgVolume * 0.5;
        
        if (volumeLevel > silenceThreshold) {
          // 检测到较大声音时停止当前播放
          if (isPlaying.value) {
            stopCurrentAudio();
          }
          
          if (!isSpeaking && !isProcessing) {
            startNewRecording();
            isSpeaking = true;
            volumeHistory = [volumeLevel];
          }
          // 重置无声音计时器
          clearTimeout(noSoundTimer);
          noSoundTimer = setTimeout(() => {
            if (isContinuousMode.value) {
              stopContinuousMode();
              showStatus(`Continuous chat ended due to ${formatTimeout(inactivityTimeout.value)} inactivity`, 'info');
            }
          }, inactivityTimeout.value * 1000); // 使用设置的超时时间值
          
          clearTimeout(silenceTimer.value);
          silenceTimer.value = null;
        } else if (isSpeaking && volumeDropped && !silenceTimer.value) {
          silenceTimer.value = setTimeout(async () => {
            if (isSpeaking && !isProcessing) {
              isProcessing = true;
              isSpeaking = false;
              await finishRecording();
              isProcessing = false;
              volumeHistory = [];
              silenceTimer.value = null;
            }
          }, silenceDelay);
        }
        
        if (isMonitoring && !isPlaying.value && !isProcessing.value && !isGeneratingAudio.value) {
          requestAnimationFrame(checkVolume);
        }
      };
      
      checkVolume();
      
      return () => {
        isMonitoring = false;
        clearTimeout(noSoundTimer);
      };
    };

    const startNewRecording = () => {
      console.log('startNewRecording called', {
        mediaRecorderExists: !!mediaRecorder.value,
        mediaRecorderState: mediaRecorder.value?.state
      });
      
      audioChunks.value = [];
      if (mediaRecorder.value && mediaRecorder.value.state === 'inactive') {
        console.log('Starting media recorder');
        mediaRecorder.value.start();
        continuousModeState.value = 'listening';
      } else {
        console.log('Media recorder not ready or already recording');
      }
    };

    // 修改 finishRecording 方法
    const finishRecording = async () => {
      console.log('finishRecording called', {
        mediaRecorderExists: !!mediaRecorder.value,
        mediaRecorderState: mediaRecorder.value?.state,
        chunksLength: audioChunks.value.length
      });
      
      if (mediaRecorder.value && mediaRecorder.value.state === 'recording') {
        console.log('Stopping recording and processing audio');
        continuousModeState.value = 'processing';
        
        try {
          // 创建一个 Promise 来等待录音数据
          const audioData = await new Promise((resolve, reject) => {
            const chunks = [];
            const timeout = setTimeout(() => {
              reject(new Error('Timeout waiting for audio data'));
            }, 5000);

            // 先停止录音
            mediaRecorder.value.stop();
            
            mediaRecorder.value.ondataavailable = (event) => {
              if (event.data.size > 0) {
                chunks.push(event.data);
              }
            };
            
            mediaRecorder.value.onstop = () => {
              clearTimeout(timeout);
              resolve(chunks);
            };
          });
          
          if (audioData.length > 0) {
            const audioBlob = new Blob(audioData, { type: 'audio/wav' });
            console.log('Sending audio blob:', audioBlob.size);
            
            // 发送音频数据进行转录
            await sendAudioToOpenAI(audioBlob);
            
            // 在转录完成后立即发送消息
            if (userInput.value.trim()) {
              await sendMessage();
            }
          } else {
            console.log('No audio data to process');
          }
        } catch (err) {
          console.error('Error in finishRecording:', err);
          showStatus('Failed to process audio', 'error');
        } finally {
          console.log('Finishing recording process');
          continuousModeState.value = 'listening';
          audioChunks.value = []; // 清空音频数据
        }
      } else {
        console.log('Media recorder not in recording state');
      }
    };

    // 确保在组件卸载时清理资源
    onUnmounted(() => {
      stopContinuousMode();
    });

    // 在 setup 中添加
    const isMicrophoneEnabled = ref(true);

    // 添加麦克风控制方法
    const toggleMicrophoneAccess = async () => {
      if (isMicrophoneEnabled.value) {
        // 如果当前是启用状态，则关闭麦克风
        isMicrophoneEnabled.value = false;
        
        // 停止所有录音相关的活动
        if (isRecording.value) {
          await toggleRecording();
        }
        if (isContinuousMode.value) {
          stopContinuousMode();
        }
        
        // 确保释放所有媒体流
        if (mediaRecorder.value?.stream) {
          mediaRecorder.value.stream.getTracks().forEach(track => {
            track.stop();
            track.enabled = false;
          });
        }
        
        // 关闭音频上下文
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }
        
        // 重置所有相关状态
        isRecording.value = false;
        isContinuousMode.value = false;
        continuousModeState.value = 'waiting';
        
        showStatus('Microphone disabled', 'info');
      } else {
        // 如果当前是禁用状态，则请求麦克风权限
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach(track => track.stop()); // 立即停止流，只是测试权限
          isMicrophoneEnabled.value = true;
          showStatus('Microphone enabled', 'success');
        } catch (err) {
          console.error('Failed to get microphone permission:', err);
          showStatus('Failed to enable microphone', 'error');
        }
      }
    };

    // 添加状态图标计算属性
    const getStatusIcon = computed(() => {
      if (isPlaying.value) {
        return 'fa-volume-up';
      }
      if (isGeneratingAudio.value) {
        return 'fa-spinner fa-spin';
      }
      if (isProcessing.value) {
        return 'fa-spinner fa-spin';
      }
      switch (continuousModeState.value) {
        case 'listening':
          return 'fa-microphone';
        case 'processing':
          return 'fa-spinner fa-spin';
        case 'waiting':
          return 'fa-clock';
        default:
          return 'fa-microphone-slash';
      }
    });

    // 添加新的 ref 来跟踪正在生成音频的消息
    const generatingAudioMessageId = ref(null);

    // 添加判断消息是否正在生成音频的方法
    const isMessageGeneratingAudio = (messageId) => {
      return generatingAudioMessageId.value === messageId;
    };

    // 添加获取播放按钮标题的方法
    const getPlayButtonTitle = (messageId) => {
      if (isMessageGeneratingAudio(messageId)) return 'Generating audio...';
      if (isMessagePlaying(messageId)) return 'Stop';
      return 'Play message';
    };

    // 添加获取播放按钮图标的方法
    const getPlayButtonIcon = (messageId) => {
      if (isMessageGeneratingAudio(messageId)) return 'fa-spinner fa-spin';
      if (isMessagePlaying(messageId)) return 'fa-square';
      return 'fa-play';
    };

    // 添加计算属性获取状态消息
    const getStatusMessage = computed(() => {
      if (isPlaying.value) {
        return 'Playback in progress...';
      }
      if (isGeneratingAudio.value) {
        return 'Generating audio response...';
      }
      if (isProcessing.value) {
        return 'Processing your message...';
      }
      return continuousModeMessage.value;
    });

    // 添加暂停录音方法
    const pauseRecording = () => {
      if (mediaRecorder.value?.state === 'recording') {
        try {
          mediaRecorder.value.pause();
          continuousModeState.value = 'paused';
          showStatus('Recording paused', 'info');
        } catch (err) {
          console.error('Error pausing recording:', err);
        }
      }
    };

    // 添加恢复录音方法
    const resumeRecording = async () => {
      if (!isContinuousMode.value || !isMicrophoneEnabled.value) return;
      
      if (isPlaying.value || isProcessing.value || isGeneratingAudio.value) {
        return;
      }
      
      try {
        // 不是简单地恢复，而是完全重新初始化
        await reinitializeAudioContext();
        showStatus('Recording resumed', 'success');
      } catch (err) {
        console.error('Failed to resume recording:', err);
        showStatus('Failed to resume recording', 'error');
        stopContinuousMode();
      }
    };

    // 添加暂停音量监测方法
    const pauseVolumeMonitoring = () => {
      if (audioContext.value) {
        try {
          audioContext.value.suspend();
        } catch (err) {
          console.error('Error suspending audio context:', err);
        }
      }
    };

    // 添加恢复音量监测方法
    const resumeVolumeMonitoring = () => {
      if (audioContext.value?.state === 'suspended') {
        try {
          audioContext.value.resume();
        } catch (err) {
          console.error('Error resuming audio context:', err);
        }
      }
    };

    // 添加新的 ref
    const inactivityTimeout = ref(30); // 默认30秒

    // 添加格式化超时时间的方法
    const formatTimeout = (seconds) => {
      if (seconds < 60) {
        return `${seconds} seconds`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 0) {
        return `${minutes} minutes`;
      }
      return `${minutes}m ${remainingSeconds}s`;
    };

    // 添加 watch 来保存超时设置到本地存储
    watch(inactivityTimeout, (newValue) => {
      try {
        localStorage.setItem('inactivityTimeout', newValue.toString());
      } catch (e) {
        console.error('Failed to save inactivity timeout:', e);
      }
    }, { immediate: true });

    // 在组件挂载时加载保存的超时设置
    onMounted(() => {
      const savedTimeout = localStorage.getItem('inactivityTimeout');
      if (savedTimeout) {
        inactivityTimeout.value = parseInt(savedTimeout, 10);
      }
    });

    // 添加重新初始化音频上下文的方法
    const reinitializeAudioContext = async () => {
      try {
        // 清理现有的音频上下文
        if (audioContext.value) {
          await audioContext.value.close();
          audioContext.value = null;
        }
        if (analyser.value) {
          analyser.value = null;
        }

        // 重新初始化音频上下文和分析器
        audioContext.value = new (window.AudioContext || window.webkitAudioContext)();
        analyser.value = audioContext.value.createAnalyser();
        analyser.value.fftSize = 2048;

        // 重新获取麦克风流
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const source = audioContext.value.createMediaStreamSource(stream);
        source.connect(analyser.value);

        // 重新初始化录音机
        if (mediaRecorder.value) {
          mediaRecorder.value.stream.getTracks().forEach(track => track.stop());
        }
        mediaRecorder.value = new MediaRecorder(stream);
        audioChunks.value = [];

        // 设置录音数据处理
        mediaRecorder.value.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunks.value.push(event.data);
          }
        };

        // 重新开始音量监测
        await startVolumeMonitoring();

        // 更新状态
        continuousModeState.value = 'listening';
      } catch (err) {
        console.error('Error reinitializing audio context:', err);
        showStatus('Failed to reinitialize audio system', 'error');
        stopContinuousMode();
      }
    };

    // 添加新的状态
    const handleQuote = (quoteText) => {
      // 如果输入框已有内容，在新行添加引用
      userInput.value = userInput.value 
        ? userInput.value + '\n\n' + quoteText 
        : quoteText;
      
      // 聚焦输入框并滚动到底部
      nextTick(() => {
        const inputEl = document.querySelector('.chat-input');
        if (inputEl) {
          inputEl.focus();
          inputEl.scrollTop = inputEl.scrollHeight;
        }
      });
    };

    return {
      modes,
      selectedMode,
      duckImage,
      isAnimating,
      userInput,
      chatHistory,
      silentResponse,
      clearChat,
      clearAllChats,
      handleTranscription,
      handleProcessingStart,
      handleProcessingError,
      sendMessage,
      voiceInteraction,
      updateCode,
      handleCodeChange,
      showVoiceSettings,
      toggleVoiceSettings,
      chatContainer,
      messagesWrapper,
      toggleRecording,
      startRecordingTimer,
      stopRecordingTimer,
      isRecording,
      recordingDuration,
      isComposing,
      isSending,
      handleEnterPress,
      parseMessage,
      copiedStates,
      highlightCode,
      copyCode,
      showStatus,
      isPlaying,
      stopCurrentAudio,
      isProcessing,
      mediaRecorder,
      audioChunks,
      audioContext,
      sendAudioToOpenAI,
      ttsEnabled,
      selectedVoice,
      volume,
      voiceSettingsDialog,
      openVoiceSettings,
      closeVoiceSettings,
      voices,
      quackSound,
      quackAudio,
      playDuckAnimation,
      localCode,
      saveChatsToLocalStorage,
      loadChatsFromLocalStorage,
      chatHistories,
      clearConfirmDialog,
      showClearConfirm,
      closeClearConfirm,
      handleClearDialogClick,
      confirmClear,
      isLoading,
      isGeneratingAudio,
      loadingMessage,
      scrollToBottom,
      volumeIcon,  // 确保返回 volumeIcon
      statusMessage,
      statusType,
      statusIcon,
      playMessage,
      isMessagePlaying,
      playingMessageId,
      cancelRecording,
      isContinuousMode,
      continuousModeState,
      continuousModeMessage,
      toggleContinuousMode,
      toggleMicrophoneAccess,
      isMicrophoneEnabled,
      getStatusIcon,
      isMessageGeneratingAudio,
      getPlayButtonTitle,
      getPlayButtonIcon,
      getStatusMessage,
      pauseRecording,
      resumeRecording,
      pauseVolumeMonitoring,
      resumeVolumeMonitoring,
      inactivityTimeout,
      formatTimeout,
      handleQuote,
    }
  }
}
</script>