<template>
  <div class="app-container">
    <div class="app-content">
      <RubberDuckAssistant 
        :currentCode="currentCode"
        @update:currentCode="updateCurrentCode"
      />
    </div>
  </div>
</template>

<script>
import RubberDuckAssistant from './components/RubberDuckAssistant.vue'

export default {
  name: 'App',
  components: {
    RubberDuckAssistant
  },
  data() {
    return {
      currentCode: {
        code: '',
        language: 'javascript'
      }
    }
  },
  methods: {
    updateCurrentCode(newCode) {
      this.currentCode = newCode;
    }
  }
}
</script>

<style>
/* 全局样式 */
:root {
  color-scheme: dark;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* 响应式容器 */
.app-container {
  min-height: 100vh;
  padding: clamp(1rem, 2vw, 2rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.app-content {
  width: 100%;
  max-width: min(98vw, 2000px);
  margin: 0 auto;
  height: calc(100vh - clamp(2rem, 4vw, 4rem));
}

/* 媒体查询 */
@media (max-width: 768px) {
  .app-container {
    padding: 0.75rem;
  }
  
  .app-content {
    max-width: 100%;
    height: calc(100vh - 1.5rem);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .app-container {
    padding: 1rem;
  }
  
  .app-content {
    max-width: 99vw;
    height: calc(100vh - 2rem);
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .app-content {
    max-width: 98vw;
  }
}

@media (min-width: 1441px) and (max-width: 1920px) {
  .app-content {
    max-width: 97vw;
  }
}

@media (min-width: 1921px) {
  .app-content {
    max-width: 96vw;
  }
}

/* 自定义滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* 全局过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 全局玻璃态效果 */
.glass-morphism {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.05) inset;
  border-radius: 16px;
}

/* 内嵌玻璃态效果 */
.glass-inset {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  box-shadow: 
    0 4px 16px rgba(0, 0, 0, 0.2) inset,
    0 0 0 1px rgba(255, 255, 255, 0.03);
}

/* 确保所有输入控件在暗色主题下可用 */
input, select, textarea {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.75rem;
  border-radius: 10px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 0 0 2px rgba(255, 255, 255, 0.05),
    0 4px 16px rgba(0, 0, 0, 0.2);
}

/* 按钮样式 */
button {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  font-weight: 500;
}

button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

button:active {
  transform: translateY(0);
}

/* 优化文本渲染 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
</style>